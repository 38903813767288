import { globalEnvironment } from 'helpers/global-environment';
import { ReviewLike, ReviewLikeType } from 'like-api';
import { Review } from 'reviews-api';
import { LocalStorageService } from './LocalStorageService';
import { ReviewLikedItems } from './ReviewLikedItems';
import { UserCentricsExtendedWindow } from './UserCentricsExtendedWindow';
import {
  DefaultApi as LikeApi,
  Configuration as LikeConfiguration
} from 'like-api';
import { determineLikeApiUrl } from 'helpers/urls';
import { Environment } from 'models/Environment';
import { TotalLikeCount } from 'models/TotalLikeCount';

export class ReviewLikeService {
  private localStorageService?: LocalStorageService;

  constructor(private environment?: Environment) {
    /* istanbul ignore if */
    if (typeof window === 'undefined') {
      return;
    }

    this.localStorageService = new LocalStorageService();
  }

  public async like(
    review: Review,
    likeType: ReviewLikeType
  ): Promise<boolean> {
    const success: boolean = await this.sendLikeInformation({
      reviewID: review.id,
      likeType
    });

    /* istanbul ignore else */
    if (success) {
      new TotalLikeCount(review, likeType).increment();
      this.storeLikeInformationInLocalStore(review, likeType);
    }

    return success;
  }

  /* istanbul ignore next: has been covered by interaction tests */
  public acceptLikeConsent(): void {
    if (!this.userCentricsAvailable()) {
      return;
    }

    (window as UserCentricsExtendedWindow)
      .UC_UI!.acceptService('JwNk0aRFO')
      .then(() => {});
  }

  private storeLikeInformationInLocalStore(
    review: Review,
    likeType: ReviewLikeType
  ): void {
    /* istanbul ignore if */
    if (!this.localStorageService || !this.localStorageService.isAvailable()) {
      return;
    }

    const likedReviewsIds: ReviewLikedItems = this.likedReviews;
    likedReviewsIds[review.id] = likedReviewsIds[review.id] || {};
    likedReviewsIds[review.id][likeType] = new TotalLikeCount(
      review,
      likeType
    ).get();

    this.likedReviews = likedReviewsIds;
  }

  public get likedReviews(): ReviewLikedItems {
    /* istanbul ignore if */
    if (!this.localStorageService) {
      return {};
    }
    const likedReviews = this.localStorageService.select<ReviewLikedItems>(
      globalEnvironment.reviews.like.localStorageItemName
    );
    return likedReviews || {};
  }

  private set likedReviews(reviews: ReviewLikedItems) {
    /* istanbul ignore if */
    if (!this.localStorageService) {
      return;
    }
    this.localStorageService.insertOrUpdate<ReviewLikedItems>(
      globalEnvironment.reviews.like.localStorageItemName,
      reviews
    );
  }

  public userCentricsAvailable(): boolean {
    const userCentricsExtendedWindow = window as UserCentricsExtendedWindow;

    return (
      typeof window !== 'undefined' &&
      !!userCentricsExtendedWindow.UC_UI &&
      userCentricsExtendedWindow.UC_UI.isInitialized()
    );
  }

  /* istanbul ignore next: has been covered by interaction tests */
  public get reviewCookiesAccepted(): boolean {
    let isReviewLikeConsentAccepted;

    const reviewLikeConsent = (
      window as UserCentricsExtendedWindow
    ).UC_UI?.getServicesBaseInfo().find((service) => {
      return service.id === 'JwNk0aRFO';
    });

    isReviewLikeConsentAccepted = !!reviewLikeConsent?.consent.status;

    return isReviewLikeConsentAccepted;
  }

  /* istanbul ignore next: has been covered by interaction tests */
  private async sendLikeInformation(reviewLike: ReviewLike): Promise<boolean> {
    try {
      await new LikeApi(
        new LikeConfiguration({
          basePath: determineLikeApiUrl(this.environment!)
        })
      ).sendLike({
        reviewLike
      });
      return true;
    } catch (error) {
      console.error(
        `Review like could not be saved in the API. Reason: ${error}`
      );
      return false;
    }
  }
}
