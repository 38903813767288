/* istanbul ignore file: this file is only for configuration */

import { Environment } from 'models/Environment';
import { appendHost } from './appendHost';

type ApiUrls = {
  [environment in Environment]: string;
};

const PROFILE_API_URLS: ApiUrls = {
  loc: 'https://profile-api.seo.trustedshops.com/v1',
  vrt: appendHost('/api/profile'),
  integr: 'https://profile-api.seo-integr.trustedshops.com/v1',
  qa: 'https://profile-api.seo-qa.trustedshops.com/v1',
  production: 'https://profile-api.seo.trustedshops.com/v1'
};

const SHOP_REVIEWS_API_URLS: ApiUrls = {
  loc: 'https://profile-pages-reviews-api.trustedshops.com',
  vrt: appendHost('/api/shopreviews'),
  integr: 'https://profile-pages-reviews-api-qa.trustedshops.com',
  qa: 'https://profile-pages-reviews-api-qa.trustedshops.com',
  production: 'https://profile-pages-reviews-api.trustedshops.com'
};

const TOP_FLOP_API_URLS: ApiUrls = {
  loc: 'https://shop-profiles-topflop-api.trustedshops.com/v2',
  vrt: appendHost('/api/topflop'),
  integr: 'https://shop-profiles-topflop-api-qa.trustedshops.com/v2',
  qa: 'https://shop-profiles-topflop-api-qa.trustedshops.com/v2',
  production: 'https://shop-profiles-topflop-api.trustedshops.com/v2'
};

const LIKE_API_URLS: ApiUrls = {
  loc: 'https://shop-profiles-reviews-api.trustedshops.com/review',
  vrt: appendHost('/api'),
  integr: 'https://shop-profiles-reviews-api-qa.trustedshops.com/review',
  qa: 'https://shop-profiles-reviews-api-qa.trustedshops.com/review',
  production: 'https://shop-profiles-reviews-api.trustedshops.com/review'
};

const CONSUMER_PORTAL_URLS: ApiUrls = {
  loc: 'https://my-qa.trustedshops.com/',
  vrt: 'http://my.trustedshops-local.com/',
  integr: 'https://my-qa.trustedshops.com/',
  qa: 'https://my-qa.trustedshops.com/',
  production: 'https://my.trustedshops.com/'
};

const SHOPSEARCH_API_URLS: ApiUrls = {
  loc: 'https://shop-search-api.trustedshops.com/shopsearch',
  vrt: appendHost('/api/shopsearch/'),
  integr: 'https://shop-search-api-qa.trustedshops.com/shopsearch',
  qa: 'https://shop-search-api-qa.trustedshops.com/shopsearch',
  production: 'https://shop-search-api.trustedshops.com/shopsearch'
};

const CONTROL_CENTER_URLS: { [environment in Environment]: string } = {
  loc: 'http://app.etrusted.koeln',
  vrt: 'http://app.etrusted.koeln',
  integr: 'http://app.etrusted.koeln',
  qa: 'https://app.etrusted.site',
  production: 'https://app.etrusted.com'
};

const REVIEW_REPORT_URLS: { [environment in Environment]: string } = {
  loc: 'https://review-reporter.seo-qa.trustedshops.com/v1/reports',
  vrt: appendHost('/api/review-reporter'),
  integr: 'https://review-reporter.seo-qa.trustedshops.com/v1/reports',
  qa: 'https://review-reporter.seo-qa.trustedshops.com/v1/reports',
  production: 'https://review-reporter.seo.trustedshops.com/v1/reports'
};

export const APPLE_TOUCH_ICON_LINK = '/apple-touch-icon.png';
export const FAVICON_32x32_LINK = '/favicon-32x32.png';
export const FAVICON_16x16_LINK = '/favicon-16x16.png';
export const FAVICON_LINK = '/favicon.ico';

export function determineShopReviewsApiUrl(environment: Environment) {
  return SHOP_REVIEWS_API_URLS[environment];
}

export function determineProfilesApiUrl(environment: Environment) {
  return PROFILE_API_URLS[environment];
}

export function determineTopFlopApiUrl(environment: Environment) {
  return TOP_FLOP_API_URLS[environment];
}

export function determineLikeApiUrl(environment: Environment) {
  return LIKE_API_URLS[environment];
}

export function determineConsumerPortalUrl(environment: Environment) {
  return CONSUMER_PORTAL_URLS[environment];
}

export function determineShopSearchApiUrl(environment: Environment) {
  return SHOPSEARCH_API_URLS[environment];
}

export function determineControlCenterUrl(environment: Environment) {
  return CONTROL_CENTER_URLS[environment];
}

export function getMultiStoreUrl(accountName: string): string {
  return `/company/${accountName}/`;
}

export function determineReviewReportUrl(environment: Environment) {
  return REVIEW_REPORT_URLS[environment];
}

export const googleTagManager = 'https://www.googletagmanager.com';
