// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Review Search API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains the distributed review counts for each star category
 * @export
 * @interface Counts
 */
export interface Counts {
    /**
     * 
     * @type {number}
     * @memberof Counts
     */
    _1?: number;
    /**
     * 
     * @type {number}
     * @memberof Counts
     */
    _2?: number;
    /**
     * 
     * @type {number}
     * @memberof Counts
     */
    _3?: number;
    /**
     * 
     * @type {number}
     * @memberof Counts
     */
    _4?: number;
    /**
     * 
     * @type {number}
     * @memberof Counts
     */
    _5?: number;
}

export function CountsFromJSON(json: any): Counts {
    return CountsFromJSONTyped(json, false);
}

export function CountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Counts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_1': !exists(json, '1') ? undefined : json['1'],
        '_2': !exists(json, '2') ? undefined : json['2'],
        '_3': !exists(json, '3') ? undefined : json['3'],
        '_4': !exists(json, '4') ? undefined : json['4'],
        '_5': !exists(json, '5') ? undefined : json['5'],
    };
}

export function CountsToJSON(value?: Counts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '1': value._1,
        '2': value._2,
        '3': value._3,
        '4': value._4,
        '5': value._5,
    };
}

