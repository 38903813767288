/* istanbul ignore file: our visual regression tests are enough to test this file */

import React, { createContext, ReactNode, useContext, useState } from 'react';
import { shopCertificateStateIs } from 'helpers/shopCertificateStateIs';
import { CertificationState } from 'models/CertificationState';
import { Profile } from 'profile-api';

type certificationContextType = {
  isCertificateProduction: boolean;
  isCertificateDisabled: boolean;
};

const certificationContextDefaultValues: certificationContextType = {
  isCertificateProduction: false,
  isCertificateDisabled: false
};

type Props = {
  children: ReactNode;
  profile: Profile;
};

const CertificationContext = createContext<certificationContextType>(
  certificationContextDefaultValues
);

export function useCertification() {
  return useContext(CertificationContext);
}
export function CertificationContextProvider({ children, profile }: Props) {
  const [isCertificateProduction] = useState(
    shopCertificateStateIs(profile, CertificationState.Production)
  );
  const [isCertificateDisabled] = useState(
    shopCertificateStateIs(profile, CertificationState.Disabled)
  );

  const value = {
    isCertificateProduction,
    isCertificateDisabled
  };

  return (
    <CertificationContext.Provider value={value}>
      {children}
    </CertificationContext.Provider>
  );
}
