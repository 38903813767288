/* istanbul ignore next: the cost of writing unit tests for such a simple wrapper
 * (incl. localStorage mocks) is too high, compared to the value such unit tests would bring */
export class LocalStorageService {
  public isAvailable(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    try {
      return !!localStorage;
    } catch (error) {
      return false;
    }
  }

  public insertOrUpdate<T>(key: string, value: T): void {
    if (!this.isAvailable()) {
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
      return;
    }
  }

  public select<T>(key: string): T | undefined {
    if (!this.isAvailable()) {
      return;
    }

    let value;
    try {
      value = localStorage.getItem(key);

      if (!value) {
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }

    return JSON.parse(value);
  }
}
