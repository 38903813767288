/* istanbul ignore file: our visual regression tests are enough to test this file */

import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ModalTypes } from 'models/ModalTypes';

type modalContextType = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  modalVisible: ModalTypes | null;
  setModal: (modalName: ModalTypes | null, modalData?: any) => void;
  setModalData: (modalData?: any) => void;
  modalData: any;
};

const modalContextDefaultValues: modalContextType = {
  isModalOpen: false,
  setIsModalOpen: () => {},
  setModal: () => {},
  setModalData: () => {},
  modalVisible: null,
  modalData: null
};

type Props = {
  children: ReactNode;
};

const ModalContext = createContext<modalContextType>(modalContextDefaultValues);

export function useModal() {
  return useContext(ModalContext);
}
export function ModalContextProvider({ children }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState<ModalTypes | null>(null);
  const [modalData, setModalData] = useState(null);

  function setModal(modalName: ModalTypes | null, modalData?: any) {
    setModalData(modalData);
    setModalVisible(modalName);
    setIsModalOpen(!!modalName);
  }

  const value = {
    isModalOpen,
    setIsModalOpen,
    modalVisible,
    setModal,
    modalData,
    setModalData
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
