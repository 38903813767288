import { TLD } from './TargetMarket';

// https://en.wikipedia.org/wiki/Country_code_top-level_domain
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export const languageCodePerTld: { [tld in TLD]: string } = {
  // TLD == language code
  de: 'de',
  fr: 'fr',
  pt: 'pt',
  es: 'es',
  it: 'it',
  pl: 'pl',
  fi: 'fi',
  ro: 'ro',
  nl: 'nl',

  // TLD != language code
  'co.uk': 'en',
  at: 'de',
  be: 'nl',
  dk: 'da',
  se: 'sv',
  cz: 'cs',
  eu: 'en',
  com: 'en',
  ch: 'de'
};
