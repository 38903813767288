import App, { AppContext, AppProps } from 'next/app';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import { LocalesAllowedValues } from 'models/Locales';
import {
  determineCountryCodeFromTld,
  determineTldFromHost
} from 'helpers/url-mapper';
import { languageCodePerTld } from 'models/languageCodePerTld';
import { StoreProvider } from 'context/store';
import { GTM_ID } from 'helpers/gtm';
import Script from 'next/script';
import { googleTagManager } from 'helpers/urls';
import * as Sentry from '@sentry/browser';
import 'polyfill-object.fromentries';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import { locales } from '@trustedshops/tps-seo-shared-frontend-react-components';

/**
 * Loads translations from JSON files on server,
 * and from __NEXT_DATA__ cache on client.
 * As dynamic `import` is Promise-based, we have to use `getInitialProps`.
 * Using `require` would not solve the problem of having 1 file with all translations.
 */
async function getMessages(languageCode: string, locale: string) {
  if (typeof window === 'undefined') {
    const fileNotFound = () => {};
    const [
      languageSpecificTranslations,
      countrySpecificTranslations
    ]: LocalesAllowedValues[] = await Promise.all(
      [
        import(`locales/${languageCode}.json`),
        import(`locales/${locale}.json`)
      ].map((promise) => promise.then((file) => file.default, fileNotFound))
    );

    const [
      languageSpecificSharedTranslations,
      countrySpecificSharedTranslations
    ] = [languageCode, locale].map(
      (attribute) => locales[attribute as keyof typeof locales] || {}
    );

    return {
      ...languageSpecificSharedTranslations,
      ...languageSpecificTranslations,
      ...countrySpecificSharedTranslations,
      ...countrySpecificTranslations
    };
  }

  return window.__NEXT_DATA__.props.pageProps.messages as LocalesAllowedValues;
}

async function getInitialProps(appContext: AppContext) {
  const { pageProps } = await App.getInitialProps(appContext);

  const tld = determineTldFromHost(pageProps.host);
  const languageCode = pageProps.languageCode || languageCodePerTld[tld];
  const countryCode = determineCountryCodeFromTld(tld);
  const locale = `${languageCode}-${countryCode}`;

  return {
    pageProps: {
      ...pageProps,
      languageCode,
      locale,
      tld,
      messages: await getMessages(languageCode, locale)
    }
  };
}

function ProfilePagesApp({
  Component,
  pageProps
}: AppProps<
  Awaited<ReturnType<typeof getInitialProps>>['pageProps']
>): JSX.Element {
  const router = useRouter();
  const { languageCode, locale, messages } = pageProps;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  router.locale = languageCode;

  useEffect(() => {
    document.documentElement.lang = languageCode;
  });

  return (
    <StoreProvider pageProps={pageProps}>
      <IntlProvider
        locale={locale}
        defaultLocale={languageCode}
        messages={messages}
        onError={(error) => {
          const { message } = error;

          if (message.includes('using default message')) {
            return;
          }

          if (
            message.match(
              /Missing locale data for locale.+Using default locale.+as fallback/
            )
          ) {
            return;
          }

          Sentry.captureException(error);
        }}
      >
        <Component
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          {...pageProps}
        />
        <Script defer id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${googleTagManager}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`}
        </Script>
      </IntlProvider>
    </StoreProvider>
  );
}

ProfilePagesApp.getInitialProps = getInitialProps;

export default ProfilePagesApp;
