// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Review Like API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReviewLikeType {
    Review = 'review',
    Shopanswer = 'shopanswer'
}

export function ReviewLikeTypeFromJSON(json: any): ReviewLikeType {
    return ReviewLikeTypeFromJSONTyped(json, false);
}

export function ReviewLikeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewLikeType {
    return json as ReviewLikeType;
}

export function ReviewLikeTypeToJSON(value?: ReviewLikeType | null): any {
    return value as any;
}

