/* eslint-disable import/no-anonymous-default-export */
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export default {
  dsn: SENTRY_DSN,
  enableTracing: false,
  tracesSampleRate: 0,
  tracesSampler: (samplingContext) => {
    return 0;
  },
  tracePropagationTargets: [],
  autoSessionTracking: false,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // This is the official list of of common ignore rules for everyday things,
  // like Facebook, Chrome extensions, and so forth.
  // See: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'Failed to fetch',
    'Unexpected end of input',
    "Can't find variable: metadata",
    "Can't find variable: iom",
    "Can't find variable: ProductPageDetector",
    'Intl.NumberFormat is undefined',
    "Cannot read properties of undefined (reading 'cdcs')",
    "Cannot read properties of null (reading 'replace')",
    'Non-Error exception captured with keys: body, headers, isBase64Encoded',
    'Performance.measure: Given mark name, beforeRender, is unknown',
    "undefined is not an object (evaluating 'c.parentFrameId')",
    "SyntaxError: Unexpected token 'else'",
    "SyntaxError: Unexpected identifier 'https'",
    "Identifier 'CURRENCY_LIST' has already been declared",
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    'Text content does not match server-rendered HTML.',
    "undefined is not an object (evaluating 'a.g')",
    "undefined is not an object (evaluating 'n.length')",
    "undefined is not an object (evaluating 'a.h')",
    'Non-Error exception captured with keys: body, headers, isBase64Encoded',
    'null is not an object (evaluating \'Object.prototype.hasOwnProperty.call(o,"telephone")\')'
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ]
};
