import { CountryAlpha2 } from 'models/CountryAlpha2';
import { Environment } from 'models/Environment';
import { TargetMarket, targetMarketPerTld, TLD } from 'models/TargetMarket';
import { GetServerSidePropsContext } from 'next';
import { sanitizeXForwardedHostHeader } from './sanitizeXForwardedHostHeader';

export const DEFAULT_ENVIRONMENT = Environment.production;
const DEFAULT_TLD = 'eu';
const DEFAULT_TARGET_MARKET = targetMarketPerTld[DEFAULT_TLD];

export function determineHost(
  context: GetServerSidePropsContext
): string | undefined {
  /* istanbul ignore if */
  if (typeof window === 'undefined') {
    const { headers } = context.req;
    return (
      sanitizeXForwardedHostHeader(headers['x-forwarded-host']) || headers.host
    );
  }

  return window.location.host;
}

export function determineProtocol() {
  return process.env.NEXT_PUBLIC_PROTOCOL!;
}

export function determineEnvironmentFromHost(host?: string): Environment {
  if (!host) {
    return DEFAULT_ENVIRONMENT;
  }

  const matches = host.match(/^(loc|vrt|integr|qa)\.trustedshops\./);

  if (!matches || !matches[1]) {
    return DEFAULT_ENVIRONMENT;
  }

  return matches[1] as Environment;
}

export function determineTldFromHost(host?: string): TLD {
  if (!host) {
    return DEFAULT_TLD;
  }

  const matches = host.match(/\.trustedshops\.([^\/^\/:]*)/);
  if (!matches || !matches[1]) {
    return DEFAULT_TLD;
  }

  return matches[1] as TLD;
}

/* istanbul ignore next */
export function determineCountryCodeFromTld(tld: TLD): string {
  switch (tld) {
    case 'co.uk':
      return CountryAlpha2.GreatBritain;
    case 'com':
      return CountryAlpha2._EUROPE;
    default:
      return tld.toUpperCase();
  }
}

export const determineTargetMarketFromHost = (host?: string): TargetMarket => {
  const tld = determineTldFromHost(host);

  if (targetMarketPerTld[tld]) {
    return targetMarketPerTld[tld];
  }

  return DEFAULT_TARGET_MARKET;
};
