import {
  CertificationState,
  CertificationStateVisibility
} from 'models/CertificationState';
import { Profile } from 'profile-api';

export function shopCertificateStateIs(
  profile: Profile,
  expectedStatus: CertificationState | CertificationStateVisibility
): boolean {
  if (!profile || !expectedStatus) {
    return false;
  }

  if (
    profile.memberDetails?.certificationStateId &&
    profile.memberDetails.certificationStateId === expectedStatus
  ) {
    return true;
  }

  const isVisibleCert = [
    CertificationState.Production,
    CertificationState.Disabled
  ].includes(profile.memberDetails?.certificationStateId!);

  if (expectedStatus === CertificationStateVisibility.Invisible) {
    return !profile.memberDetails?.certificationStateId || !isVisibleCert;
  }

  if (expectedStatus === CertificationStateVisibility.Visible) {
    return !!profile.memberDetails?.certificationStateId && isVisibleCert;
  }

  return false;
}
