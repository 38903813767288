export enum CountryAlpha2 {
  Germany = 'DE',
  Austria = 'AT',
  GreatBritain = 'GB',
  Spain = 'ES',
  France = 'FR',
  Italy = 'IT',
  Netherlands = 'NL',
  Poland = 'PL',
  Portugal = 'PT',
  Switzerland = 'CH',
  Belgium = 'BE',
  _EUROPE = 'EU',
  Finland = 'FI',
  Sweden = 'SE',
  Denmark = 'DK',
  Romania = 'RO',
  CzechRepublic = 'CZ'
}
