import { Review } from 'reviews-api';
import { ReviewLikeType } from 'like-api';

export class TotalLikeCount {
  constructor(
    private review: Review,
    private reviewLikeType: ReviewLikeType
  ) {}

  get() {
    switch (this.reviewLikeType) {
      case ReviewLikeType.Review:
        return this.review.totalLikeCount || 0;

      case ReviewLikeType.Shopanswer:
        return this.review.reply?.totalLikeCount || 0;
    }
  }

  increment() {
    switch (this.reviewLikeType) {
      case ReviewLikeType.Review:
        this.review.totalLikeCount = (this.review.totalLikeCount || 0) + 1;
        break;

      case ReviewLikeType.Shopanswer:
        this.review.reply!.totalLikeCount =
          (this.review.reply!.totalLikeCount || 0) + 1;
        break;
    }
  }
}
