/* istanbul ignore file: this file is only for configuration */

import { Environment } from 'models/Environment';
import { TsIdOrChannelRefGetSortByEnum } from 'reviews-api';

export enum ProfileType {
  myTs = 'myTs',
  eTrusted = 'eTrusted'
}

interface GlobalEnvironment {
  vatRates: {
    [key: string]: number;
  };
  reviews: {
    sort: {
      defaultSort: {
        [ProfileType.myTs]: TsIdOrChannelRefGetSortByEnum;
        [ProfileType.eTrusted]: TsIdOrChannelRefGetSortByEnum;
      };
    };
    like: {
      localStorageItemName: 'likedReviews';
      consentTemplateId: 'JwNk0aRFO';
    };
    pageSize: number;
    verifiedProviders: string[];
  };
  localeConnectedToEUWebpage: string[];
  targetMarketConnectedToEUWebpage: string[];
  environmentsWithReviewLikeFunctionality: Environment[];
  testShopIds: string[];
}

interface CountryConnectedToEUWebpage {
  tld: string;
  localeIso2: string;
  countryAlpha2: string;
}

const countriesConnectedToEUWebpage: CountryConnectedToEUWebpage[] = [
  {
    tld: 'fi',
    localeIso2: 'fi',
    countryAlpha2: 'FI'
  },
  {
    tld: 'dk',
    localeIso2: 'da',
    countryAlpha2: 'DK'
  },
  {
    tld: 'se',
    localeIso2: 'sv',
    countryAlpha2: 'SE'
  },
  {
    tld: 'cz',
    localeIso2: 'cs',
    countryAlpha2: 'CZ'
  },
  {
    tld: 'ro',
    localeIso2: 'ro',
    countryAlpha2: 'RO'
  }
];

export const globalEnvironment: GlobalEnvironment = {
  vatRates: {
    DEU: 0.19,
    AUT: 0.2,
    CHE: 0.08,
    BEL: 0.21,
    DNK: 0.25,
    ESP: 0.18,
    FIN: 0.23,
    FRA: 0.196,
    GBR: 0.2,
    GRC: 0.23,
    ITA: 0.22,
    LUX: 0.15,
    NLD: 0.19,
    NOR: 0.25,
    POL: 0.23,
    PRT: 0.23,
    SWE: 0.25,
    EUO: 0.19
  },
  reviews: {
    sort: {
      defaultSort: {
        [ProfileType.myTs]: TsIdOrChannelRefGetSortByEnum.Date,
        [ProfileType.eTrusted]: TsIdOrChannelRefGetSortByEnum.Relevance
      }
    },
    like: {
      localStorageItemName: 'likedReviews',
      consentTemplateId: 'JwNk0aRFO'
    },
    pageSize: 20,
    verifiedProviders: ['Trusted Shops GmbH']
  },
  localeConnectedToEUWebpage: countriesConnectedToEUWebpage.map(
    (country) => country.localeIso2
  ),
  targetMarketConnectedToEUWebpage: countriesConnectedToEUWebpage.map(
    (country) => country.countryAlpha2
  ),
  environmentsWithReviewLikeFunctionality: [
    Environment.vrt,
    Environment.qa,
    Environment.production
  ],
  testShopIds: ['X079198F3BC11FA13F8980EB6879E2677']
};
