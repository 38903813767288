// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Review Search API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetaData,
    MetaDataFromJSON,
    MetaDataFromJSONTyped,
    MetaDataToJSON,
    Review,
    ReviewFromJSON,
    ReviewFromJSONTyped,
    ReviewToJSON,
} from './';

/**
 * Contains the array of reveiws and metadata
 * @export
 * @interface ReviewResponse
 */
export interface ReviewResponse {
    /**
     * 
     * @type {Array<Review>}
     * @memberof ReviewResponse
     */
    reviews: Array<Review>;
    /**
     * 
     * @type {MetaData}
     * @memberof ReviewResponse
     */
    metaData: MetaData;
}

export function ReviewResponseFromJSON(json: any): ReviewResponse {
    return ReviewResponseFromJSONTyped(json, false);
}

export function ReviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviews': ((json['reviews'] as Array<any>).map(ReviewFromJSON)),
        'metaData': MetaDataFromJSON(json['metaData']),
    };
}

export function ReviewResponseToJSON(value?: ReviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviews': ((value.reviews as Array<any>).map(ReviewToJSON)),
        'metaData': MetaDataToJSON(value.metaData),
    };
}

