// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Review Search API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Counts,
    CountsFromJSON,
    CountsFromJSONTyped,
    CountsToJSON,
} from './';

/**
 * Contains the number of total reviews for the given shop
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    totalReviewCount: number;
    /**
     * 
     * @type {Counts}
     * @memberof MetaData
     */
    counts?: Counts;
}

export function MetaDataFromJSON(json: any): MetaData {
    return MetaDataFromJSONTyped(json, false);
}

export function MetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalReviewCount': json['totalReviewCount'],
        'counts': !exists(json, 'counts') ? undefined : CountsFromJSON(json['counts']),
    };
}

export function MetaDataToJSON(value?: MetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalReviewCount': value.totalReviewCount,
        'counts': CountsToJSON(value.counts),
    };
}

