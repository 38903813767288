/* istanbul ignore file: our visual regression tests are enough to test this file */

import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ModalContextProvider } from './modal';
import { FilterableQueryParams } from 'models/FilterableQueryParams';
import { DEFAULT_ENVIRONMENT } from 'helpers/url-mapper';
import { Environment } from 'models/Environment';
import { ReviewResponse, TsIdOrChannelRefGetSortByEnum } from 'reviews-api';
import { TotalLikeCounts } from 'models/TotalLikeCounts';
import { buildTotalLikeCounts } from './buildTotalLikeCounts';
import { TopFlopServerResponse } from 'top-flop-api';
import { CertificationContextProvider } from 'context/certification';
import { targetMarketPerTldType } from 'models/TargetMarket';
import {
  getMappedReviewCountsForFilters,
  MappedReviewCountsForFilters
} from 'helpers/getMappedReviewCountsForFilters';
import { CompanyProfile } from 'models/Profile';

type storeContextType = {
  profile: CompanyProfile;
  isMemberProfile: boolean;
  isNonMemberProfile: boolean;
  isFreeAccount: boolean;
  isOpenProfile: boolean;
  pageCount: number;
  path: string;
  sortSelectionDefault: TsIdOrChannelRefGetSortByEnum;
  activeFilters: FilterableQueryParams;
  setActiveFilters?: (filterableQueryParams: FilterableQueryParams) => void;
  environment: Environment;
  reviewLikeFunctionalityEnabled: boolean;
  setReviewLikeFunctionalityEnabled: (
    reviewLikeFunctionalityEnabled: boolean
  ) => void;
  totalLikeCounts: TotalLikeCounts;
  setTotalLikeCounts: (totalLikeCounts: TotalLikeCounts) => void;
  host: string;
  callbacks: { scrollToReviews?: () => void };
  tld: string;
  reviewCountsForFilters?: MappedReviewCountsForFilters | null;
  setReviewCountsForFilters: (
    reviewCountsForFilters: MappedReviewCountsForFilters | null
  ) => void;
  currentPageSectionIdPosition: number;
  setCurrentPageSectionIdPosition: (
    currentPageSectionIdPosition: number
  ) => void;
};

const storeContextDefaultValues: storeContextType = {
  profile: {} as CompanyProfile,
  isMemberProfile: false,
  isNonMemberProfile: false,
  isFreeAccount: false,
  isOpenProfile: false,
  pageCount: 1,
  path: '',
  sortSelectionDefault: TsIdOrChannelRefGetSortByEnum.Relevance,
  activeFilters: {
    page: undefined,
    stars: [],
    sort: '',
    searchTerm: undefined
  },
  setActiveFilters: () => {},
  environment: DEFAULT_ENVIRONMENT,
  reviewLikeFunctionalityEnabled: false,
  setReviewLikeFunctionalityEnabled: () => {},
  totalLikeCounts: {},
  setTotalLikeCounts: () => {},
  host: '',
  callbacks: {},
  tld: 'de',
  reviewCountsForFilters: null,
  setReviewCountsForFilters: () => {},
  currentPageSectionIdPosition: 0,
  setCurrentPageSectionIdPosition: () => {}
};

type Props = {
  children: ReactNode;
  pageProps: {
    profile: CompanyProfile;
    isMemberProfile: boolean;
    isNonMemberProfile: boolean;
    isFreeAccount: boolean;
    isOpenProfile: boolean;
    reviews: ReviewResponse;
    page: number;
    pageCount: number;
    path: string;
    stars: number[];
    sort: TsIdOrChannelRefGetSortByEnum;
    searchTerm: string;
    sortSelectionDefault: TsIdOrChannelRefGetSortByEnum;
    environment: Environment;
    topFlopReviews: TopFlopServerResponse;
    host: string;
    tld: keyof targetMarketPerTldType;
  };
};

const StoreContext = createContext<storeContextType>(storeContextDefaultValues);

export function useStore() {
  return useContext(StoreContext);
}

export function StoreProvider({ pageProps, children }: Props) {
  const [profile] = useState(pageProps.profile);
  const [activeFilters, setActiveFilters] = useState({});
  const [environment] = useState(pageProps.environment);
  const [tld] = useState(pageProps.tld);
  const [reviewLikeFunctionalityEnabled, setReviewLikeFunctionalityEnabled] =
    useState(false);
  const initialTotalLikeCounts = buildTotalLikeCounts(
    pageProps?.reviews?.reviews || [],
    pageProps?.topFlopReviews || []
  );
  const [totalLikeCounts, setTotalLikeCounts] = useState(
    initialTotalLikeCounts
  );
  const [host] = useState(pageProps.host);
  const [reviewCountsForFilters, setReviewCountsForFilters] = useState(
    getMappedReviewCountsForFilters(pageProps.reviews?.metaData?.counts)
  );
  const [currentPageSectionIdPosition, setCurrentPageSectionIdPosition] =
    useState(0);

  const value = {
    profile,
    isMemberProfile: pageProps.isMemberProfile,
    isNonMemberProfile: pageProps.isNonMemberProfile,
    isFreeAccount: pageProps.isFreeAccount,
    isOpenProfile: pageProps.isOpenProfile,
    pageCount: pageProps.pageCount,
    path: pageProps.path,
    sortSelectionDefault: pageProps.sortSelectionDefault,
    activeFilters: {
      ...activeFilters,
      page: pageProps.page,
      stars: pageProps.stars,
      sort: pageProps.sort,
      searchTerm: pageProps.searchTerm
    },
    setActiveFilters,
    environment,
    reviewLikeFunctionalityEnabled,
    setReviewLikeFunctionalityEnabled,
    totalLikeCounts: {
      ...totalLikeCounts,
      ...initialTotalLikeCounts
    },
    setTotalLikeCounts,
    host,
    callbacks: {},
    tld,
    reviewCountsForFilters,
    setReviewCountsForFilters,
    currentPageSectionIdPosition,
    setCurrentPageSectionIdPosition
  };

  return (
    <CertificationContextProvider profile={pageProps.profile}>
      <ModalContextProvider>
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
      </ModalContextProvider>
    </CertificationContextProvider>
  );
}
