import { ReviewLikeService } from 'services/ReviewLikeService';
import { ReviewLikeType } from 'like-api';
import { Review } from 'reviews-api';
import { TotalLikeCounts } from 'models/TotalLikeCounts';
import { TopFlopServerResponse } from 'top-flop-api';
import { TotalLikeCount } from 'models/TotalLikeCount';

function combineAllReviews(
  reviews: Review[],
  topFlopReviews: TopFlopServerResponse
): Review[] {
  if (topFlopReviews?.TopReview?.id && topFlopReviews?.FlopReview?.id) {
    return [...reviews, topFlopReviews.TopReview, topFlopReviews.FlopReview];
  }

  return reviews;
}

export function buildTotalLikeCounts(
  reviews: Review[],
  topFlopReviews: TopFlopServerResponse
) {
  const { likedReviews } = new ReviewLikeService();
  const totalLikeCounts: TotalLikeCounts = {};
  const allReviews = combineAllReviews(reviews, topFlopReviews);

  for (const review of allReviews) {
    totalLikeCounts[review.id] = totalLikeCounts[review.id] || {};

    for (const likeType of [ReviewLikeType.Review, ReviewLikeType.Shopanswer]) {
      let likeCount = new TotalLikeCount(review, likeType).get();
      const cachedCount = likedReviews[review.id]?.[likeType] || 0;

      if (cachedCount > likeCount) {
        likeCount = cachedCount;
      }

      totalLikeCounts[review.id][likeType] = [likeCount, cachedCount > 0];
    }
  }
  return totalLikeCounts;
}
