import { Counts } from 'reviews-api';

export interface MappedReviewCountsForFilters {
  '1': number | undefined;
  '2': number | undefined;
  '3': number | undefined;
  '4': number | undefined;
  '5': number | undefined;
}
export function getMappedReviewCountsForFilters(
  reviewCounts?: Counts
): MappedReviewCountsForFilters | null {
  if (!reviewCounts) {
    return null;
  }

  return {
    1: reviewCounts?._1,
    2: reviewCounts?._2,
    3: reviewCounts?._3,
    4: reviewCounts?._4,
    5: reviewCounts?._5
  };
}
