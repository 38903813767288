// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Review Search API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    ReviewResponse,
    ReviewResponseFromJSON,
    ReviewResponseToJSON,
} from '../models';

export interface TsIdOrChannelRefGetRequest {
    tsIdOrChannelRef: string;
    pageSize?: number;
    pageIndex?: number;
    stars?: Array<TsIdOrChannelRefGetStarsEnum>;
    order?: TsIdOrChannelRefGetOrderEnum;
    sortBy?: TsIdOrChannelRefGetSortByEnum;
    searchTerm?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Returns reviews for a given channelRef
     */
    async tsIdOrChannelRefGetRaw(requestParameters: TsIdOrChannelRefGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviewResponse>> {
        if (requestParameters.tsIdOrChannelRef === null || requestParameters.tsIdOrChannelRef === undefined) {
            throw new runtime.RequiredError('tsIdOrChannelRef','Required parameter requestParameters.tsIdOrChannelRef was null or undefined when calling tsIdOrChannelRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageIndex !== undefined) {
            queryParameters['pageIndex'] = requestParameters.pageIndex;
        }

        if (requestParameters.stars) {
            queryParameters['stars'] = requestParameters.stars.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{tsIdOrChannelRef}`.replace(`{${"tsIdOrChannelRef"}}`, encodeURIComponent(String(requestParameters.tsIdOrChannelRef))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewResponseFromJSON(jsonValue));
    }

    /**
     * Returns reviews for a given channelRef
     */
    async tsIdOrChannelRefGet(requestParameters: TsIdOrChannelRefGetRequest, initOverrides?: RequestInit): Promise<ReviewResponse> {
        const response = await this.tsIdOrChannelRefGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum TsIdOrChannelRefGetStarsEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}
/**
    * @export
    * @enum {string}
    */
export enum TsIdOrChannelRefGetOrderEnum {
    Desc = 'DESC',
    Asc = 'ASC'
}
/**
    * @export
    * @enum {string}
    */
export enum TsIdOrChannelRefGetSortByEnum {
    Relevance = 'relevance',
    Date = 'date'
}
