/**
 * Target markets that are commented out have no dedicated shop search yet.
 * Instead, users will get redirected to .eu/shops and see shops
 * of all remaining target markets.
 */
export const targetMarketPerTld = {
  de: 'DEU',
  'co.uk': 'GBR',
  fr: 'FRA',
  pt: 'PRT',
  es: 'ESP',
  it: 'ITA',
  pl: 'POL',
  at: 'AUT',
  be: 'BEL',
  fi: 'FIN',
  dk: 'DNK',
  se: 'SWE',
  cz: 'CZE',
  ro: 'ROU',
  eu: 'EUO',
  ch: 'CHE',
  nl: 'NLD',
  com: 'EUO'
};

export type targetMarketPerTldType = typeof targetMarketPerTld;
export type TLD = keyof targetMarketPerTldType;
export type TargetMarket = targetMarketPerTldType[keyof targetMarketPerTldType];
