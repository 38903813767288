export enum CertificationState {
  /** a shop has a valid certificate */
  Production = 1,
  /** valid certificate for internal testing */
  Test = 2,
  /** shop is able to be certified */
  Integration = 3,
  /** the certificate is temporarily blocked */
  Disabled = 4,
  /** the certificate is blocked */
  Cancelled = 5,
  /** the shop hasn't asked for certification yet */
  NoAudit = 6
}

export enum CertificationStateVisibility {
  Visible = 'visible',
  Invisible = 'invisible'
}
