import { determinePort } from 'helpers/determinePort';

/**
 * API Routes do not specify CORS headers, meaning they are same-origin only by default.
 * We could customize such behavior by wrapping the request handler with the cors middleware
 * (see: https://nextjs.org/docs/api-routes/api-middlewares#connectexpress-middleware-support),
 * but we decided to append the correct host, based on the environment (client/server).
 */
export function appendHost(url: string) {
  const port = determinePort();
  const host =
    typeof window === 'undefined' ? `localhost:${port}` : window.location.host;

  return `http://${host}${url}`;
}
