// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Review Search API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    Reply,
    ReplyFromJSON,
    ReplyFromJSONTyped,
    ReplyToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './';

/**
 * Contains the array of reviews and metadata
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    comment?: string;
    /**
     * submit date of the review | in milliseconds
     * @type {number}
     * @memberof Review
     */
    createdAt: number;
    /**
     * update date of the review | in milliseconds.
     * @type {number}
     * @memberof Review
     */
    updatedAt?: number;
    /**
     * 
     * @type {Reply}
     * @memberof Review
     */
    reply?: Reply;
    /**
     * 
     * @type {Customer}
     * @memberof Review
     */
    customer?: Customer;
    /**
     * 
     * @type {Transaction}
     * @memberof Review
     */
    transaction?: Transaction;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    totalLikeCount?: number;
    /**
     * the origin of the review
     * @type {string}
     * @memberof Review
     */
    provider: string;
    /**
     * is the claimed as suspicious
     * @type {boolean}
     * @memberof Review
     */
    inModeration: boolean;
    /**
     * displays the extent of the verification of the review.
     * @type {string}
     * @memberof Review
     */
    verificationStatus: ReviewVerificationStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum ReviewVerificationStatusEnum {
    MemberVerified = 'MEMBER_VERIFIED',
    OpenProfileVerified = 'OPEN_PROFILE_VERIFIED',
    External = 'EXTERNAL'
}

export function ReviewFromJSON(json: any): Review {
    return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Review {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rating': json['rating'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'createdAt': json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'reply': !exists(json, 'reply') ? undefined : ReplyFromJSON(json['reply']),
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
        'transaction': !exists(json, 'transaction') ? undefined : TransactionFromJSON(json['transaction']),
        'totalLikeCount': !exists(json, 'totalLikeCount') ? undefined : json['totalLikeCount'],
        'provider': json['provider'],
        'inModeration': json['inModeration'],
        'verificationStatus': json['verificationStatus'],
    };
}

export function ReviewToJSON(value?: Review | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rating': value.rating,
        'title': value.title,
        'comment': value.comment,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'reply': ReplyToJSON(value.reply),
        'customer': CustomerToJSON(value.customer),
        'transaction': TransactionToJSON(value.transaction),
        'totalLikeCount': value.totalLikeCount,
        'provider': value.provider,
        'inModeration': value.inModeration,
        'verificationStatus': value.verificationStatus,
    };
}

